import http from "./http";
export default (schoolId) => ({
  async index(filters) {
    return http
      .index("schools", filters)
      .then((json) => ({ schools: json }))
      .catch(async (e) => ({ schools: null, error: await e }));
  },
  async get() {
    return http
      .get("schools", schoolId)
      .then((json) => ({ school: json }))
      .catch(async (e) => ({ school: null, error: await e }));
  },
  async create(data) {
    return http
      .post("schools", data)
      .then((json) => ({ school: json }))
      .catch(async (e) => ({ school: null, error: await e }));
  },
  async update(data) {
    return http
      .put("schools", schoolId, data)
      .then((json) => ({ school: json }))
      .catch(async (e) => ({ school: null, error: await e }));
  },
  async delete() {
    return http
      .delete("schools", schoolId)
      .then((json) => ({ school: json }))
      .catch(async (e) => ({ school: null, error: await e }));
  },
});
