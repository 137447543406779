<template>
  <div>
    <el-card v-loading="isLoading" shadow>
      <el-descriptions
        class="margin-top"
        title="Detalhes da Licitação"
        :column="3"
        border
      >
        <template #extra>
          <el-button-group>
            <el-button onlyIcon type="primary" @click="openReceiptModal" circle>
              <i class="el-icon-edit"></i
            ></el-button>
            <el-popconfirm
              @confirm="deleteReceipt()"
              title="
              Remover recibo?
            "
              ><template #reference>
                <el-button onlyIcon type="danger" circle>
                  <i class="el-icon-delete"></i></el-button></template
            ></el-popconfirm>
          </el-button-group>
        </template>
        <template>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-tickets"></i>
              Nome
            </template>
            {{ public_sell?.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-info"></i>
              Estado
            </template>
            <el-tag
              size="mini"
              :type="public_sell?.status === 'active' ? 'success' : 'danger'"
              >{{ public_sell?.status_formatted }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-date"></i>
              Data de Expiração
            </template>
            {{ dateFormatter.format(new Date(public_sell?.end_at)) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-location-outline"></i>
              Empresa
            </template>
            {{ public_sell?.firm?.name }}
          </el-descriptions-item>
        </template>
      </el-descriptions>
    </el-card>

    <el-card v-loading="isLoading" shadow>
      <el-descriptions
        class="margin-top"
        title="Detalhes do Recibo"
        :column="1"
        border
      >
        <template>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-tickets"></i>
              Pedidos
            </template>
            {{ receipt?.orders?.map((order) => order.code).toString() }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-tickets"></i>
              Rota
            </template>
            {{ receipt?.route?.name }}
          </el-descriptions-item>
        </template>
      </el-descriptions>

      <el-table
        :key="tableKey"
        stripe
        :cell-style="() => 'text-align:center;'"
        :data="ReceiptProducts"
        style="width: 100%; z-index: 0"
      >
        <el-table-column
          label="produto"
          :formatter="(product) => getProductName(product)"
        />
        <el-table-column
          label="peso vendido"
          :formatter="
            (product) =>
              weightFormatter.format(getProductQuantitySold(product)) + ' kg.'
          "
        />
        <el-table-column
          label="peso máximo recibo"
          :formatter="
            (product) =>
              weightFormatter.format(getProductQuantityReceipt(product)) +
              ' kg.'
          "
        />
        <el-table-column
          label="peso vinculado a unidades"
          :formatter="
            (product) =>
              weightFormatter.format(getSchoolQuantity(product)) + ' kg.'
          "
        />
      </el-table>
    </el-card>

    <el-card v-loading="isLoading" shadow>
      <el-descriptions
        class="margin-top"
        title="Unidades de Entrega"
        :column="1"
        border
      >
        <template #extra>
          <el-button onlyIcon type="primary" @click="openSchoolModal">
            adicionar
          </el-button>
        </template>
        <template>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-tickets"></i>
              Total de Unidades
            </template>
            {{ receipt?.schools?.length || '0' }}
          </el-descriptions-item>
        </template>
      </el-descriptions>
    </el-card>
    <el-card>
      <el-table
        stripe
        :cell-style="() => 'text-align:center;'"
        :data="ReceiptSchools"
        style="width: 100%; z-index: 0"
      >
        <el-table-column prop="name" label="nome"></el-table-column>
        <el-table-column prop="zone" label="zona"></el-table-column>
        <el-table-column prop="order" label="ordem"></el-table-column>
        <el-table-column label="produtos">
          <template #default="{ row: { schoolProducts } }">
            <el-row v-for="product in schoolProducts" :key="product.uid">
              <el-col :span="12">
                {{
                  getProductName({
                    mounted: product?.mounted,
                    product: product?.mounted?.product,
                  })
                }}
              </el-col>
              <el-col :span="12">
                {{
                  weightFormatter.format(Number(product.quantity || 0)) + " kg."
                }}
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="ações" :width="130" fixed="right">
          <template #default="cat">
            <el-row type="flex" justify="end">
              <el-button-group>
                <el-button
                  onlyIcon
                  title="Editar produto"
                  type="primary"
                  circle
                  @click="openSchoolModal(cat.row)"
                >
                  <i class="el-icon-edit"></i
                ></el-button>
                <el-popconfirm
                  @confirm="deleteReceiptSchool(cat.row)"
                  title="
              Remover unidade de entrega?
            "
                  ><template #reference>
                    <el-button
                      onlyIcon
                      type="danger"
                      title="Remover unidade de entrega"
                      circle
                    >
                      <i class="el-icon-delete"></i></el-button></template
                ></el-popconfirm>
              </el-button-group>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <receipt-modal
      :receipt="receipt"
      :showModal="showReceiptModal"
      @close="showReceiptModal = false"
    />
    <school-modal
      :receipt="receipt"
      :school="selectedSchool"
      :showModal="showSchoolModal"
      @close="showSchoolModal = false"
      @should-update="fetchReceipt($route.params.receiptId)"
    />
  </div>
</template>
<script>
import ReceiptService from "../services/receipts";
import PartnerService from "../services/partners";
import PublicSellService from "../services/public_sells";
import ReceiptModal from "./modals/ReceiptModal.vue";
import SchoolModal from "./modals/SchoolModal.vue";
import {
  dateFormatter,
  currencyFormatter,
  weightFormatter,
} from "../utils/formatters";
import { notifySuccess, notifyError } from "../utils/notifiers";
import FirmService from "../services/firms";
import { uuid } from "vue-uuid";
export default {
  name: "PublicSellsReceiptDetails",
  components: {
    ReceiptModal,
    SchoolModal,
  },
  data() {
    return {
      isLoading: true,
      partner: null,
      currencyFormatter,
      dateFormatter,
      weightFormatter,
      firms: null,
      receipt: {},
      tableKey: uuid.v4(),
      public_sell: {},
      selectedSchool: null,
      showReceiptModal: false,
      showSchoolModal: false,
    };
  },
  watch: {
    receipt(receipt) {
      if (receipt?.public_sell) {
        this.public_sell = receipt.public_sell;

        this.fetchPublicSell(receipt.public_sell.uid);
      }
    },
  },
  mounted() {
    if (this.$route.params.receiptId)
      this.fetchReceipt(this.$route.params.receiptId);
    else this.$router.back();
  },
  methods: {
    openReceiptModal() {
      this.showReceiptModal = true;
    },
    openSchoolModal(school) {
      this.showSchoolModal = true;
      this.selectedSchool = school;
    },
    rebuildTable() {
      this.tableKey = uuid.v4();
    },
    getProductName({ product, mounted }) {
      return `${product?.name} ${mounted?.cutting || ""} ${
        mounted?.composition || ""
      } ${mounted?.storage || ""}`;
    },
    async deleteReceiptSchool(receiptSchool){
      await ReceiptService(this.receipt.uid).Schools(receiptSchool.uid).delete();
      this.fetchReceipt(this.receipt.uid);
    },
    getProductQuantitySold({ mounted }) {
      if (mounted)
        return this.PublicSellProductOrders[mounted.id]?.reduce(
          (acc, product) => acc + Number(product?.weight),
          0
        );

      return 0;
    },
    getProductQuantityReceipt({ mounted }) {
      if (mounted) {
        const publicSellProduct = this.ReceiptProducts?.find(
          (p) => p?.mounted?.id === mounted.id
        );

        if (publicSellProduct) return Number(publicSellProduct?.total_weight);
      }

      return 0;
    },
    getSchoolQuantity(product) {
      if(!product?.mounted?.id) return 0;

      const productMountedId = product.mounted.id;

      const schoolProduct = this.SchoolProducts.filter((product) => product.mounted.id === productMountedId);

      return schoolProduct.reduce((total, product) => total + Number(product.quantity), 0)
    },
    startLoad() {
      this.isLoading = true;
    },
    stopLoad() {
      this.isLoading = false;
    },
    async fetchReceipt(receiptId) {
      this.startLoad();
      const { receipt } = await ReceiptService(receiptId).get();

      if (receipt) this.receipt = receipt;

      this.stopLoad();
    },
    async fetchPublicSell(publicSellId) {
      const { publicSell } = await PublicSellService(publicSellId).get();

      if (publicSell) this.public_sell = publicSell;

      this.rebuildTable();
    },
    async loadFirms() {
      const { firms } = await FirmService().index();

      if (firms) this.firms = firms;
    },
    async deleteReceipt() {
      const { partner, error } = PartnerService(this.partner.uid).delete();

      if (partner) {
        notifySuccess("Parceiro removido com sucesso");
        const router = this.$router;
        setTimeout(() => {
          router.push("/");
        }, 500);
      } else notifyError("Não foi possível remover o parceiro", error.message);
    },
  },
  computed: {
    ReceiptProducts() {
      return this.public_sell?.products || [];
    },
    PublicSellOrders() {
      return this.public_sell?.orders || [];
    },
    ReceiptSchools() {
      return this.receipt?.schools || [];
    },
    SchoolProducts(){
      return this.ReceiptSchools?.map(({schoolProducts}) => schoolProducts).flat();
    },
    PublicSellProductOrders() {
      return (
        this.receipt?.orders
          ?.flatMap((order) => order?.products)
          ?.reduce((acc, product) => {
            if (acc[product?.mounted?.id])
              acc[product?.mounted?.id].push(product);
            else acc[product?.mounted?.id] = [product];
            return acc;
          }, {}) || []
      );
    },
    Partner() {
      const partner = this.partner;
      partner.accountIds = partner.bank_accounts?.map((a) => a.uid);
      return partner;
    },
    Firms() {
      return this.firms || [];
    },
  },
};
</script>
