<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-select  v-model="school_.school_id" @change="v => selectedSchool = v" size="medium" placeholder="Digite para buscar ou criar um novo" filterable
        remote allow-create value-key="uid" :remote-method="fetchSchools">
        <template #prefix>Unidade da Entrega: </template>
        <el-option v-for="item in Schools" :key="item.uid" :label="item.name" :value="item.uid">
        </el-option>
      </el-select>

      <div v-if="isNew">
        <base-input label="Tipo:" v-model="school_.type" />
        <base-input label="Responsável" v-model="school_.manager" />
        <base-input label="CNPJ:" mask="##!.###!.###!/####!-##" v-model="school_.cnpj" />
        <base-input label="Telefone:" mask="!(##!)#! ####!-####" v-model="school_.phone" />
        <base-input label="Email:" v-model="school_.email" />
        <base-input label="Endereço:" v-model="school_.address" />
        <base-input label="Zona:" mask="##" v-model="school_.zone" />
        <base-input label="Ordem:" mask="##" v-model="school_.order" />
      </div>

      <div v-for="receiptProduct in ReceiptProducts" :key="receiptProduct.uid">
        <base-input :label="getProductMountedName(receiptProduct.mounted)"
          v-model="receiptSchoolProduct[receiptProduct.uid]" type="money" :minimumFractionDigits="3"></base-input>
      </div>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false" :loading="isLoadingSave"
          :disabled="isLoadingSave">Cancelar</el-button>
        <el-button type="text" class="button" @click="save" :disabled="!ReadyToSave">Salvar</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import ReceiptService from "../../services/receipts";
import OrderService from "../../services/orders";
import SchoolService from "../../services/schools";
import RouteService from "../../services/routes";
import { notifyError, notifySuccess } from "../../utils/notifiers";
import { hasOnlyDigits, isUuid, getProductMountedName } from "../../utils/functions";

export default {
  props: ["receipt", "showModal", "school"],
  emits: ["close-modal", "should-update"],
  data() {
    return {
      school_: {},
      eventDate: [],
      isLoadingSave: false,
      public_sells: null,
      orders: null,
      routes: null,
      schools: null,
      receiptSchoolProduct: {},
      public_sell: null,
      getProductMountedName
    };
  },
  watch: {
    receipt(v) {
      //if (v) this.school_ = { ...v };

      this.receiptSchoolProduct = {};

      if (v.public_sell)
        this.public_sell = v.public_sell;

    },
    school(v){
      if(v){
        this.schools = {data: [v]};
        this.school_ = v;
        this.school_.school_id = v.uid;

        if(v.schoolProducts){
          this.receiptSchoolProduct = v.schoolProducts.reduce((mapper, schoolProduct) => {
            const receiptProductId = this.ReceiptProducts.find(receiptProduct => receiptProduct.mounted.id === schoolProduct.mounted.id)?.uid;
            mapper[receiptProductId] = schoolProduct.quantity;
            return mapper;
          }, {})
        }
      }
    }
  },
  computed: {
    ReadyToSave() {
      return !!this.school_.school_id;
    },
    ReceiptProducts() {
      return this.public_sell?.products || [];
    },
    ModalTitle() {
      return !this.isNew ? "Editar Unidade de Entrega" : "Cadastrar Unidade de Entrega";
    },
    PublicSells() {
      return this.public_sells || [];
    },
    Orders() {
      return this.orders?.data || [];
    },
    Routes() {
      return this.routes || [];
    },
    SchoolProducts(){
      return  this.school_.schoolProducts|| [];
    },
    Schools() {
      return this.schools?.data || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    isNew() {
      return this.school_.school_id && !isUuid(this.school_.school_id);
    },
  },
  methods: {
    async fetchRoutes(searchQuery) {
      const { routes } = await RouteService().index({
        limit: 25,
        searchQuery,
        type: "public",
        pagination: 1,
      });

      if (routes) this.routes = routes;
    },

    async fetchSchools(searchQuery) {
      const { schools } = await SchoolService().index({ searchQuery });

      if (schools) this.schools = schools;
    },
    async fetchOrders(searchName) {
      const { orders } = await OrderService().index({
        searchName,
        searchCpfCnpj: hasOnlyDigits(searchName) ? searchName : null,
        limit: 25,
        pagination: 1,
      });

      if (orders) this.orders = orders;
    },
    getProductQuantitiesFromSchool(){
      return this.ReceiptProducts.map((receiptProduct) => {
        return {
          product_id: receiptProduct.product_id,
          product_mounted_id: receiptProduct.mounted.id,
          mounted: receiptProduct.mounted,
          quantity: this.receiptSchoolProduct[receiptProduct.uid],
          uid: this.SchoolProducts.find(schoolProduct => schoolProduct.mounted.id === receiptProduct.mounted.id)?.uid
        };
      });
    },
    async save() {
      this.isLoadingSave = true;
      let schoolId = this.school_.school_id;

      if (this.isNew){
        var result = await this.createSchool();
        const {school} = result;

        if(school.school?.uid)
          schoolId = school.school?.uid;
      }

      if (schoolId) {
        await Promise.all([
        ReceiptService(this.receipt.uid)
          .Schools(schoolId).create({}),
          ...this.getProductQuantitiesFromSchool()
            .map((receiptProduct) => {
              if(receiptProduct.uid){
                return ReceiptService(this.receipt.uid)
                .Schools(schoolId)
                .Products(receiptProduct.uid)
                .update({ quantity: receiptProduct.quantity })
              } else
              return ReceiptService(this.receipt.uid)
                .Schools(schoolId)
                .Products()
                .create({ quantity: receiptProduct.quantity, product_id: receiptProduct.mounted.product.uid, product_mounted_id: receiptProduct.mounted.id })
            }
              
          ),
        ]);

        notifySuccess("Unidade de Entrega cadastrada com sucesso!");
        this.$emit("should-update");
        this.$emit("close");
      } else {
        notifyError('Ocorreu um erro ao cadastrar o recibo');
      }
    },
    async createSchool() {
      return await SchoolService().create({...this.school_, name: this.school_.school_id});
    },
  },
  name: "SchoolModal",
};
</script>
<style>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-1 {
  margin-top: 4px;
}

.stretch-width {
  width: 100% !important;
}
</style>
